import { Button, Divider, Modal } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { AttachmentCell } from "../PenaltiesTable";
import {
  getRequiredDocuments,
  IPenaltyBeforeUpload,
  PenaltyAttachmentLabel,
} from "../../services/penalty";
import { useEffect, useState } from "react";

const AttachmentsModal = (props: {
  onOk: any;
  onClose: any;
  onReset: any;
  open: any;
  data: IPenaltyBeforeUpload;
  onFileUpload: any;
  onFileRemove: any;
}) => {
  const [incompleteModal, setIncompleteModal] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [warningModal, setWarningModal] = useState(false);

  useEffect(() => {
    setAttachments(props.data?.attachmentsList || []);
  }, [props.data]);

  const ModalTitle = (
    <>
      <FileAddOutlined style={{ marginRight: "8px", color: "green" }} />
      Carica Allegati - {props?.data?.sennder_reference}(
      {props?.data?.tour_composition})
    </>
  );
  const justificationCategory = props.data?.justification?.category || null;
  const requiredAttachments = getRequiredDocuments(justificationCategory);

  const handleModalOk = () => {
    if (getRemainingDocument().length) setIncompleteModal(true);
    else props.onOk();
  };

  const handleIncompleteModalProceed = () => {
    setIncompleteModal(false);
    props.onOk();
  };

  const handleIncompleteModalOk = () => {
    setIncompleteModal(false);
  };

  const handleWarningModalProceed = () => {
    setWarningModal(false);
    props.onReset();
  };

  const handleWarningModalOk = () => {
    setWarningModal(false);
  };

  const getRemainingDocument = () => {
    const required = requiredAttachments.filter((doc) => doc.required);

    const uploaded =
      attachments?.map((attachment: any) => attachment.attachment_type) || [];

    const missingAttachments = required
      .filter((doc) => !uploaded.includes(doc.value))
      .map((d) => PenaltyAttachmentLabel[d.value]);

    return missingAttachments;
  };

  const handleModalClose = () => {
    if (attachments.some((d: any) => !d.confirmed)) setWarningModal(true);
    else props.onClose();
  };

  return (
    <>
      <Modal
        open={incompleteModal}
        width={700}
        title="Attenzione!"
        closable={false}
        footer={[
          <Button
            key="proceed"
            type="text"
            onClick={handleIncompleteModalProceed}
            style={{ color: "red" }}
          >
            Si, procedi
          </Button>,

          <Button key="upload" type="primary" onClick={handleIncompleteModalOk}>
            Carica doc mancanti
          </Button>,
        ]}
      >
        <>
          <p className="ant-upload-text">
            Procedere senza il documento{" "}
            <b>{getRemainingDocument().join(", ")} </b>
            potrebbe comportare un DISSERVIZIO non giustificato correttamente,
            vuoi procedere comunque?
          </p>
        </>
      </Modal>

      <Modal
        open={warningModal}
        width={700}
        title="Attenzione!"
        closable={false}
        footer={[
          <Button
            key="proceed"
            type="text"
            onClick={handleWarningModalProceed}
            style={{ color: "red" }}
          >
            Si, procedi
          </Button>,

          <Button key="upload" type="primary" onClick={handleWarningModalOk}>
            Torna indietro
          </Button>,
        ]}
      >
        <>
          <p className="ant-upload-text">
            I documenti caricati, se non confermati verranno cancellati, sei
            sicuro di voler procedere?
          </p>
        </>
      </Modal>

      <Modal
        width={700}
        title={ModalTitle}
        open={props.open}
        closable={true}
        onCancel={handleModalClose}
        footer={[
          <Button key="ok" type="primary" onClick={handleModalOk}>
            Conferma
          </Button>,
        ]}
      >
        <>
          <AttachmentCell
            data={attachments}
            requiredAttachments={requiredAttachments}
            onUpload={(value: string) =>
              props.onFileUpload(props.data._id, value)
            }
            onRemove={(value: string) =>
              props.onFileRemove(props.data._id, value)
            }
          />
          <Divider />
          <p className="ant-upload-text">
            Massimo 5 allegati (XLS, PDF, WORD, PNG, JPEG, JPG)
          </p>
          <p className="ant-upload-hint">
            I file verrano allegati alla penale nel momento in cui la si invia.
            Non sarà possibile modificarli dopo l'invio.
          </p>
        </>
      </Modal>
    </>
  );
};

export default AttachmentsModal;
