import { IPenalty, IPenaltyBeforeUpload } from "../services/penalty";
import {
  ReferenceCell,
  DelayCell,
  CategoryCell,
  JusitificationCell,
  ActionCell,
} from "../components/PenaltiesTable";
import Table, { ColumnsType } from "antd/es/table";
import { Button, Card, Popover } from "antd";
import { formatDate, mapType } from "../common/Utils";
import { UploadOutlined, FilterOutlined } from "@ant-design/icons";
import FiltersRow from "./FiltersRow";
import { PenaltyFilters } from "../common/interfaces";

const PendingPenaltiesTable = (props: {
  data: any;
  onJustificationChange: any;
  onCategoryChange: any;
  onJustifyClick: any;
  onFileUpload: any;
  loading: boolean;
  onUploadButtonClick: any;
  onFilterClick: any;
  filters: PenaltyFilters;
  onFilterRemove: any;
}): JSX.Element => {
  const columns: ColumnsType<IPenalty> = [
    {
      title: "Referenza",
      key: "sennder_reference",
      render: ReferenceCell,
      showSorterTooltip: false,
      fixed: "left",
      width: 160,
      sorter: (a, b) => {
        const value1 = a.sennder_reference || "";
        const value2 = b.sennder_reference || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Arco",
      render: (data: IPenalty) => <p>{data.route_composition}</p>,
      key: "route_composition",
      width: 100,
      sorter: (a, b) => {
        const value1 = a.route_composition || "";
        const value2 = b.route_composition || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Ritardo",
      render: (data: IPenalty) => {
        return <DelayCell data={data} />;
      },
      key: "delay_minutes",
      width: 100,
      sorter: (a, b) => {
        const value1 = a.delay_minutes || 0;
        const value2 = b.delay_minutes || 0;
        return value1 - value2;
      },
    },
    {
      title: "Tipo",
      key: "delay_type",
      render: (data: IPenalty) => <p>{mapType(data.delay_type || "-")}</p>,
      width: 100,
      sorter: (a, b) => {
        const value1 = a.delay_type || "";
        const value2 = b.delay_type || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Penale",
      render: (data: IPenalty) => {
        return <p>{data.penalty_percentage * 100}%</p>;
      },
      key: "penalty_percentage",
      width: 80,
      sorter: (a, b) => {
        const value1 = a.penalty_percentage || 0;
        const value2 = b.penalty_percentage || 0;
        return value1 - value2;
      },
    },
    {
      title: "Caricamento",
      render: (data: IPenalty) => {
        return <p>{formatDate(data.created_at || data.received_on)}</p>;
      },
      key: "created_at",
      width: 100,
      sorter: (a, b) => {
        let dateValueA = a.created_at || a.received_on;
        let dateValueB = b.created_at || b.received_on;
        const value1 = dateValueA ? new Date(dateValueA).getTime() : 0;
        const value2 = dateValueB ? new Date(dateValueB).getTime() : 0;
        return value1 - value2;
      },
    },
    {
      title: "Scadenza",
      render: (data: IPenalty) => {
        return <p>{formatDate(data.expiration_date, true)}</p>;
      },
      key: "expiration_date",
      width: 100,
      sorter: (a, b) => {
        const value1 = a.expiration_date
          ? new Date(a.expiration_date).getTime()
          : 0;
        const value2 = b.expiration_date
          ? new Date(b.expiration_date).getTime()
          : 0;
        return value1 - value2;
      },
    },
    {
      title: "Categoria",
      key: "category",
      render: (data: IPenalty) => {
        return (
          <CategoryCell
            data={data}
            onChange={(value: string) =>
              props.onCategoryChange(data._id, value)
            }
          />
        );
      },
      width: 450,
    },
    {
      title: "Giustificazione",
      key: "justification",
      render: (data: IPenalty) => {
        return (
          <JusitificationCell
            data={data}
            onChange={(value: string) =>
              props.onJustificationChange(data._id, value)
            }
          />
        );
      },
      width: 200, // Increase this value
    },
    {
      title: "Allegato",
      key: "attachment",
      render: (data: IPenaltyBeforeUpload) => {
        const hasAttachmentsCategory = data.justification?.category;
        const attachmentsButton = (
          <Button
            disabled={!data.justification?.category}
            onClick={() => props.onUploadButtonClick(data._id)}
            icon={<UploadOutlined />}
          >
            {data.attachmentsList?.length || 0} Caricati
          </Button>
        );

        if (hasAttachmentsCategory) return attachmentsButton;
        else
          return (
            <Popover content={"Seleziona prima una categoria"}>
              {attachmentsButton}
            </Popover>
          );
      },
      width: 10,
    },
    {
      title: "Invia",
      key: "send",
      render: (data: IPenalty) => {
        return (
          <ActionCell
            data={data}
            onClick={() => props.onJustifyClick(data._id)}
          />
        );
      },
      width: 100, // Increase this value
    },
  ];
  const handleFilterRemove = (filter: keyof PenaltyFilters) => {
    props.onFilterRemove(filter);
  };

  return (
    <Card className="table-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={props.onFilterClick}
        >
          Filtri
        </Button>

        <FiltersRow
          filters={props.filters}
          onFilterRemove={handleFilterRemove}
        />
      </div>
      <Table
        className="custom-table"
        loading={props.loading}
        dataSource={props.data}
        columns={columns}
        rowKey="_id"
        scroll={{ x: "max-content", y: "50vh" }}
      />
    </Card>
  );
};

export default PendingPenaltiesTable;
