import axios from "axios";
import {
  IPenalty,
  PenaltyAttachmentType,
  PenaltyCategory,
} from "./penalty.model";
import Cookies from "js-cookie";
import { PenaltyFilters } from "../../common/interfaces";

export const getSubmitted = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["NOT_APPLIED", "APPLIED", "BILLED", "TO_REVIEW"];

  const status = filters?.status || defaultStatus.join(",");
  const params = {
    ...filters,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const getPending = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["PENDING"];

  const status = filters?.status || defaultStatus.join(",");
  const params = {
    ...filters,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalties = async (penalties: IPenalty[]) => {
  const body = { penalties };
  return axios.put(`${process.env.REACT_APP_API_URL}/penalties/`, body, {
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalty = async (
  id: string,
  penalty: IPenalty,
  attachments: any
) => {
  const formData = new FormData();

  attachments.forEach((attachment: any) => {
    const file = attachment.file.originFileObj;
    if (file instanceof File) {
      formData.append(`attachment_${attachment.attachment_type}`, file);
    }
  });

  formData.append("penalty", JSON.stringify(penalty));

  return axios.put(
    `${process.env.REACT_APP_API_URL}/penalties/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const uploadAttachments = async (id: any, attachments: any) => {
  const formData = new FormData();

  for (let attachment of attachments) {
    const file = attachment.originFileObj;
    if (file instanceof File) {
      formData.append("attachment", file);
    }
  }

  return axios.post(
    `${process.env.REACT_APP_API_URL}/penalties/upload-attachments/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getRequiredDocuments = (
  category: PenaltyCategory | null
): PenaltyAttachmentType[] => {
  switch (category) {
    case "EMPTY_VEHICLE":
      return [
        { value: "FDC", required: true },
        { value: "OTHER", required: false },
      ];
    case "TRAVEL_TIME":
      return [
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: true },
        { value: "OTHER", required: false },
      ];
    case "HUB_DELAY":
      return [
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: true },
        { value: "FDC", required: false },
        { value: "OTHER", required: false },
      ];
    case "TRAFFIC":
      return [
        { value: "TRAFFIC_EVIDENCE", required: true },
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: false },
        { value: "GPS_GRAPH", required: false },

        { value: "OTHER", required: false },
      ];
    case "FERRY_DELAY":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "SHIPOWNER_COMMUNICATION", required: true },
        { value: "GPS", required: true },
        { value: "OTHER", required: false },
      ];
    case "VEHICLE_ACCIDENT":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "POLICE_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "POLICE_STOP":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "POLICE_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "HUB_OPENING":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "OTHER", required: false },
      ];

    case "BROKEN_VEHICLE":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "REPAIR_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "WRONG_DOCUMENT":
      return [
        { value: "GPS", required: true },
        { value: "FDC", required: true },
        { value: "OTHER", required: false },
      ];
    case "OTHER":
      return [{ value: "OTHER", required: true }];
    default:
      return [{ value: "OTHER", required: true }];
  }
};
