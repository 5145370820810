import {
  UploadOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Button, message, Tooltip, Upload } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  PenaltyAttachmentLabel,
  PenaltyAttachmentType,
  PenaltyAttachmentTypeValue,
} from "../../services/penalty";

const AttachmentCell = (props: {
  data: any;
  requiredAttachments: PenaltyAttachmentType[];
  onUpload: (value: any) => void;
  onRemove: (value: any) => void;
}): JSX.Element => {
  const [fileList, setFileList] = useState<
    {
      file: any;
      attachment_type: PenaltyAttachmentTypeValue;
      confirmed: boolean;
    }[]
  >([]);

  useEffect(() => {
    setFileList(props.data || []);
  }, [props.data]);

  const isOtherUnique = useMemo(() => {
    return (
      props.requiredAttachments.length === 1 &&
      props.requiredAttachments[0].value === "OTHER"
    );
    // eslint-disable-next-line
  }, [fileList]);

  const isOtherUnlocked = useMemo(() => {
    const requiredAttachments = isOtherUnique
      ? props.requiredAttachments
      : props.requiredAttachments.filter((doc) => doc.value !== "OTHER");

    return requiredAttachments.every((requiredDoc: PenaltyAttachmentType) =>
      fileList.some((file) => file.attachment_type === requiredDoc.value)
    );
  }, [fileList, props.requiredAttachments, isOtherUnique]);

  useEffect(() => {
    if (!isOtherUnlocked) {
      const otherFiles = fileList.filter(
        (file) => file.attachment_type === "OTHER"
      );

      if (otherFiles.length) {
        const uids = otherFiles.map((file) => file.file.uid);
        uids.forEach((uid) => {
          onFileRemove(uid);
        });
      }
    }
    // eslint-disable-next-line
  }, [isOtherUnlocked, fileList]);

  const onChange = (info: any, type: PenaltyAttachmentTypeValue) => {
    const { status, name, size, uid } = info.file;
    const fileAlreadyExists = fileList.some((f) => f.file.uid === uid);
    if (fileAlreadyExists) return;

    if (fileList.length === 5)
      return message.error(`Impossibile allegare più di 5 documenti`);
    const fileMb = Number((size / 1024 / 1024).toFixed(2));
    if (fileMb >= 5)
      return message.error(
        `${name} supera la dimensione massima concessa ${fileMb} MB / 5 MB`
      );

    if (status === "error") {
      return message.error(`${name} non può essere caricato.`);
    }

    setFileList((prev) => {
      return [
        ...prev,
        {
          file: { ...info.file, status: "done" },
          attachment_type: type,
          confirmed: false,
        },
      ];
    });

    props.onUpload({
      file: { ...info.file, status: "done" },
      attachment_type: type,
      confirmed: false,
    });

    message.success(
      `${name} (${PenaltyAttachmentLabel[type]}) allegato correttamente`
    );
  };

  const onFileRemove = (uid: string) => {
    const updatedFileList = fileList.filter((f: any) => f.file.uid !== uid);
    props.onRemove(uid);
    setFileList(updatedFileList);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
        justifyContent: "flex-start",
        alignContent: "flex-start",
      }}
    >
      {props.requiredAttachments.map((attachment: PenaltyAttachmentType) => {
        return (
          <div
            style={{
              width: "100%",
              height: "48%",
              display: "flex",
              gap: "10px",
              marginTop: "20px",
            }}
            key={attachment.value + attachment.required}
          >
            <Upload
              onChange={(e: any) => onChange(e, attachment.value)}
              fileList={[]}
              disabled={
                attachment.value === "OTHER" &&
                !isOtherUnique &&
                !isOtherUnlocked
              }
            >
              {attachment.value === "OTHER" &&
              !isOtherUnique &&
              !isOtherUnlocked ? (
                <Tooltip title="Carica tutti gli allegati per poter caricare 'Altro' ">
                  <Button icon={<UploadOutlined />} disabled>
                    Carica
                  </Button>
                </Tooltip>
              ) : (
                <Button icon={<UploadOutlined />}>Carica</Button>
              )}
            </Upload>
            <div>
              <b>
                {PenaltyAttachmentLabel[attachment.value]} (
                {attachment.required ? "Necessario" : "A supporto"})
              </b>
              {!fileList.filter((f) => f.attachment_type === attachment.value)
                .length && (
                <>
                  <br />
                  <i style={{ color: "#808080" }}>Carica uno o più allegati</i>
                </>
              )}
              {fileList
                .filter((f) => f.attachment_type === attachment.value)
                .map((f) => f.file)
                .map((file) => {
                  return (
                    <p
                      key={file.uid}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <PaperClipOutlined style={{ color: "#f57c00" }} />
                        {file.name}
                      </span>{" "}
                      <DeleteOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => onFileRemove(file.uid)}
                      />
                    </p>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentCell;
